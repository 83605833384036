import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
import withPageEntry from '../../withPageEntry';
import FaqCollapse from './FaqCollapse';
import MailForm from './MailForm';

function Contact(props) {
  const [dimension] = useOutlet('dimension');
  const [bannerDimension, setBannerDimension] = React.useState(null);
  const mobile = dimension.rwd === 'mobile';

  React.useLayoutEffect(() => {
    const elem = document.getElementById('rev-banner');
    const box = elem.getBoundingClientRect();
    setBannerDimension(box);
    AppActions.setLoading(false);
  }, [dimension.innerWidth]);

  return (
    <Wrapper rwd={dimension.rwd} dimension={dimension} id="vb-ContactPage">
      <div
        id="rev-banner"
        className="banner"
        style={{
          height: (bannerDimension?.width * 760) / 1920, // 1920*760
        }}>
        <img
          alt="contact-banner"
          src="../../images/contact-banner.jpg"
          style={{width: '100%', height: '100%', objectFit: 'cover'}}
        />
      </div>
      <section className={mobile ? 'section mobile' : 'section'}>
        <div
          className={`constrain-content ${
            dimension.rwd === 'desktop' ? 'row' : ''
          }`}
          style={{alignItems: 'flex-end'}}>
          <div
            style={{
              flexBasis: dimension.innerWidth / 2.5,
              paddingRight: dimension.rwd === 'desktop' ? 40 : 0,
            }}>
            <div className={mobile ? 'subtitle mobile' : 'subtitle'}>
              CONTACT US
            </div>
            <div className={mobile ? 'title mobile' : 'title'}>聯絡我們</div>
            <p
              className="content"
              style={{
                marginBottom: 20,
                marginTop: 25,
              }}>{`歡迎透過表單留言諮詢，我們會盡快與您聯繫。`}</p>
            <MailForm />
          </div>
          <div style={{display: 'flex', flexBasis: dimension.innerWidth / 2.5}}>
            <img
              alt="contact-1"
              src={'../../images/contact-1.jpg'}
              style={{
                width: '100%',
                height:
                  dimension.rwd === 'desktop'
                    ? 1240 / 2.5
                    : dimension.innerWidth / 1.13,
                objectFit: 'cover',
              }}
            />
          </div>
        </div>
      </section>
      <section
        className={mobile ? 'section mobile' : 'section'}
        style={{backgroundColor: '#f6f6f6'}}>
        <div className="constrain-content">
          <div className={mobile ? 'subtitle mobile' : 'subtitle'}>FAQ</div>
          <div className="title">常見問答</div>
          <p
            className="content"
            style={{
              marginTop: 25,
            }}>{`若對於服務項目有其他需求及疑問，歡迎透過產品諮詢或填寫意見表單與我們聯繫，我們會盡快為您服務。`}</p>
          <FaqCollapse />
        </div>
      </section>
      <section className={mobile ? 'section mobile' : 'section'}>
        <div className="constrain-content">
          <div className={mobile ? 'subtitle mobile' : 'subtitle'}>
            FOLLOW US
          </div>
          <div className={mobile ? 'title mobile' : 'title'}>加入我們</div>
          <p
            className="content"
            style={{
              marginTop: 25,
            }}>{`歡迎追蹤微媞嚴選官方社群，關注最新動態與優惠活動。`}</p>
          <div className="line" style={{marginTop: 30, marginBottom: 50}} />
          <div
            className={`${dimension.rwd === 'desktop' && 'row'}`}
            style={{alignItems: 'flex-end'}}>
            <div
              className="row"
              style={{
                flex: 1,
                flexWrap: 'wrap',
                justifyContent:
                  dimension.rwd !== 'desktop' ? 'center' : 'flex-start',
              }}>
              <a
                className="social-item"
                // style={{marginLeft: 20}}
                href={'https://line.me/R/ti/p/@V-Care'}
                target="_blank"
                rel="noreferrer">
                <img
                  alt="line-color"
                  src="../../images/line-color.png"
                  style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
                />
                <p className="contact-title">{`Line@生活圈`}</p>
                {/* <p>@vbeauty-tpe</p> */}
              </a>

              <a
                className="social-item"
                href={'https://www.facebook.com/vbeautygroup.vcare/'}
                target="_blank"
                rel="noreferrer">
                <img
                  alt="fb-color"
                  src="../../images/fb-color.png"
                  style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
                />
                <p className="contact-title">{`粉絲專頁`}</p>
                {/* <p>@vbeauty-tc</p> */}
              </a>

              <a
                className="social-item"
                href={'https://www.instagram.com/vbeautygroup.vcare/'}
                target="_blank"
                rel="noreferrer">
                <img
                  alt="ig-color"
                  src="../../images/ig-color.png"
                  style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
                />
                <p className="contact-title">{`Instagram`}</p>
                {/* <p>@vbeauty-tc</p> */}
              </a>

              <a
                className="social-item"
                href={
                  'https://www.youtube.com/channel/UC-i45UhOXtjU3OFgzAGAVDg'
                }
                target="_blank"
                rel="noreferrer">
                <img
                  alt="yt-color"
                  src="../../images/yt-color.png"
                  style={{width: '60%', maxHeight: 60, objectFit: 'contain'}}
                />
                <p className="contact-title">{`YouTube`}</p>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: var(--topNavBarHeight);
  & > .banner {
    width: 100%;
    height: 600px;
  }
  .social-item {
    width: 90px;
    text-align: center;
    cursor: pointer;
    margin: ${(props) => (props.isMobile ? '15px 5px' : '15px 30px')};
    & img {
      min-width: 80px;
    }
    & > p {
      font-size: 13px;
      letter-spacing: 1.86px;
      font-weight: 300;
    }
    .contact-title {
      font-weight: 500;
      margin-top: 10px;
      color: #858585;
      white-space: break-spaces;
    }
  }
`;

export default withPageEntry(Contact);
