import React from 'react';
import {Button, Form, Input, Select, Row, Col, Checkbox, Modal} from 'antd';
import * as JStorageActions from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {CheckCircleTwoTone} from '@ant-design/icons';

let {Option} = Select;

function fail() {
  Modal.info({
    title: '失敗',
    icon: <ExclamationCircleOutlined />,
    content: '信件傳送失敗，請確認填寫資訊或稍後再試',
    okText: '確認',
  });
}

function confirm() {
  Modal.info({
    title: '傳送成功',
    icon: <CheckCircleTwoTone />,
    content: '信件傳送成功！將會有人員與您聯繫',
    okText: '確認',
  });
}

function MailForm() {
  const [actions] = useOutlet('actions');
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      await actions.setLoading(true);
      await JStorageActions.createDocument('mail', values);
      confirm();
      form.resetFields();
    } catch (ex) {
      console.log('EX', ex);
      fail();
    } finally {
      await actions.setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    fail();
  };
  return (
    <div>
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: '請輸入您的姓名!',
                },
              ]}>
              <Input size="large" placeholder="您的姓名" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="gender"
              rules={[
                {
                  required: true,
                  message: '請選擇您的性別!',
                },
              ]}>
              <Select size="large" placeholder="先生/女士" allowClear>
                <Option value="先生">先生</Option>
                <Option value="女士">女士</Option>
                <Option value="其他">其他</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <ContactInput />
        <Form.Item
          name="freetime"
          rules={[
            {
              required: true,
              message: '請輸入您方便的聯繫時段!',
            },
          ]}>
          <Select
            size="large"
            showSearch={true}
            placeholder="方便聯繫時段"
            allowClear>
            <Option value="10:00~12:00">10:00~12:00</Option>
            <Option value="12:00~17:00">12:00~17:00</Option>
            <Option value="18:00~21:00">18:00~21:00</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="content"
          rules={[
            {
              required: true,
              message: '請輸入您想詢問的內容!',
            },
          ]}>
          <Input.TextArea size="large" placeholder="詢問內容" />
        </Form.Item>
        <Form.Item
          name="agreement"
          valuePropName="checked"
          r
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error('請先閱讀及勾選同意')),
            },
          ]}>
          <Checkbox>
            我已確實閱讀
            <a target="_blank" href="/privacy-policy">
              <span
                style={{
                  color: 'palevioletred',
                  borderBottom: '1px solid palevioletred',
                }}>
                個資法說明
              </span>
            </a>
            內容，勾選同意方可送出表單
          </Checkbox>
        </Form.Item>

        <Form.Item style={{textAlign: 'left'}}>
          <Button
            type="primary"
            htmlType="submit"
            style={{height: 36, padding: '5px 25px'}}>
            確認送出
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

const ContactInput = ({value = {}, onChange}) => {
  const [contactData, setContactData] = React.useState('');
  const [contactType, setContactType] = React.useState('phone');

  const triggerChange = (changedValue) => {
    onChange?.({
      contactData,
      contactType,
      ...value,
      ...changedValue,
    });
  };

  const onTextChange = (e) => {
    triggerChange({
      contactData: e.target.value,
    });
  };

  const onContactTypeChange = (newContactType) => {
    if (!('currency' in value)) {
      setContactType(newContactType);
    }

    triggerChange({
      contactType: newContactType,
    });
  };

  return (
    <Row gutter={12}>
      <Col span={8}>
        <Form.Item
          name="contact_type"
          rules={[
            {
              required: true,
              message: '請選擇聯絡方式',
            },
          ]}>
          <Select
            size="large"
            value={value.contactType || contactType}
            onChange={onContactTypeChange}
            placeholder="選擇聯繫方式">
            <Option value="聯繫電話">聯繫電話</Option>
            <Option value="電子信箱">電子信箱</Option>
            <Option value="LINE ID">LINE ID</Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={16}>
        <Form.Item
          name="contact_data"
          rules={[
            {
              required: true,
              message: '請填寫聯絡方式',
            },
          ]}>
          <Input
            type="text"
            size="large"
            placeholder="請填寫您的聯絡方式"
            value={value.contactData || contactData}
            onChange={onTextChange}
            style={{
              flex: 1,
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default MailForm;
